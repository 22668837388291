<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <base-input
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      :label="`${$t('COMMON.ORGANIZATION')} (*)`"
      :placeholder="$t('COMMON.ORGANIZATION')"
    >
      <organization-selector
        :allowNone="true"
        :organization="purchasesDelivery.organization.id"
        :filterable="true"
        :showAll="false"
        :disabled="!!purchasesDelivery.id"
        @organizationChanged="
          (organizationId) => {
            purchasesDelivery.organization.id = organizationId;
            purchasesDelivery.allowedLocations = [];
            purchasesDelivery.issuer.id = null;
            purchasesDelivery.purchasesOrder.id = null;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <base-input
      :label="`${$t('COMMON.LOCATION')}`"
      :placeholder="$t('COMMON.LOCATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
    >
      <locations-selector
        :locations="purchasesDelivery.allowedLocations"
        :filterable="true"
        :showAll="false"
        :multiple="true"
        :organization="purchasesDelivery.organization.id"
        @locationsChanged="
          (locations) => {
            purchasesDelivery.allowedLocations = locations;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.location" />

    <base-input
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_PURCHASES_ORDERS)"
      :label="`${$t('COMMON.PURCHASES_ORDERS')}`"
      :placeholder="$t('COMMON.PURCHASES_ORDERS')"
    >
      <purchases-order-selector
        :allowNone="true"
        :purchasesOrder="purchasesDelivery.purchasesOrder.id"
        :filterable="true"
        :showAll="false"
        :disabled="!!purchasesDelivery.id"
        :filterOrganization="purchasesDelivery.organization.id"
        :filterStatus="ORDER_STATUS_VALIDATED"
        @purchasesOrderChanged="
          (purchasesOrderId, purchasesOrder) => {
            purchasesDelivery.purchasesOrder.id = purchasesOrderId;
            if (purchasesOrder) {
              purchasesDelivery.issuer.id = purchasesOrder.issuer.id;
            }
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <base-input
      :label="`${$t('PURCHASES_DELIVERIES.EXPIRATION_TIME')} (*)`"
      :placeholder="$t('PURCHASES_DELIVERIES.EXPIRATION_TIME')"
    >
      <flat-picker
        :config="{
          allowInput: true,
          minDate: 'today',
          locale: $flatPickrLocale(),
        }"
        class="form-control datepicker"
        v-model="purchasesDelivery.expiration_time"
        @on-change="
          () => {
            onFormChanged();
          }
        "
      >
      </flat-picker>
    </base-input>
    <validation-error :errors="apiValidationErrors.expiration_time" />

    <base-input :label="`${$t(`PURCHASES_DELIVERIES.SELECT_SUPPLIERS`)} (*)`">
      <supplier-selector
        :allowNone="true"
        :supplier="purchasesDelivery.issuer.id"
        :filterable="true"
        :showAll="false"
        :filterOrganization="purchasesDelivery.organization.id"
        :disabled="!!purchasesDelivery.id"
        @supplierChanged="
          (supplierId) => {
            purchasesDelivery.issuer.id = supplierId;
            onFormChanged();
          }
        "
      />
    </base-input>

    <h2 class="mt-5">{{ $t("COMMON.NOTE") }}</h2>
    <html-editor v-model="purchasesDelivery.excerpt" @change="onFormChanged()">
    </html-editor>
    <validation-error :errors="apiValidationErrors.excerpt" />

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          purchasesDelivery.id
            ? $t("PURCHASES_DELIVERIES.EDIT_PURCHASES_DELIVERY")
            : $t("PURCHASES_DELIVERIES.ADD_PURCHASES_DELIVERY")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import moment from "moment";
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { ORDER_STATUS_VALIDATED } from "@/constants/orders";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import PurchasesOrderSelector from "@/components/PurchasesOrderSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import SupplierSelector from "@/components/SupplierSelector.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    PurchasesOrderSelector,
    LocationsSelector,
    flatPicker,
    HtmlEditor,
    SupplierSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["purchasesDeliveryData", "formErrors", "loading"],

  data() {
    let purchasesDeliveryData = { ...this.purchasesDeliveryData };
    purchasesDeliveryData = this.$fillUserOrganizationData(
      purchasesDeliveryData
    );

    return {
      purchasesDelivery: purchasesDeliveryData,
      ORDER_STATUS_VALIDATED: ORDER_STATUS_VALIDATED,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let purchasesDeliveryData = cloneDeep(this.purchasesDelivery);
      purchasesDeliveryData.expiration_time = moment(
        purchasesDeliveryData.expiration_time
      ).toISOString();
      purchasesDeliveryData = this.$fillUserOrganizationData(
        purchasesDeliveryData
      );
      if (!purchasesDeliveryData.purchasesOrder.id) {
        delete purchasesDeliveryData.purchasesOrder;
      }
      this.$emit("purchasesDeliveriesubmitted", purchasesDeliveryData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    purchasesDeliveryData(purchasesDeliveryData) {
      if (purchasesDeliveryData) {
        this.purchasesDelivery = {
          ...this.purchasesDelivery,
          ...cloneDeep(purchasesDeliveryData),
        };
        if (!this.purchasesDelivery.organization) {
          this.purchasesDelivery.organization = {
            type: "organizations",
            id: null,
          };
        }
      }
    },
  },
};
</script>
