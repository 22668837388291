export const ORDER_STATUS_DRAFT = "DRAFT";
export const ORDER_STATUS_CANCELED = "CANCELED";
export const ORDER_STATUS_VALIDATED = "VALIDATED";
export const ORDER_STATUS_COMPLETED = "COMPLETED";

export const orderStatusesOption = {
  [ORDER_STATUS_DRAFT]: "Brouillon",
  [ORDER_STATUS_CANCELED]: "Annulée",
  [ORDER_STATUS_VALIDATED]: "Validée",
  [ORDER_STATUS_COMPLETED]: "Completée",
};
